
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>
          <img src='https://nduval.s3.eu-west-1.amazonaws.com/reveal/logo.png'></img>  
        </p>
        
      </header>
    </div>
  );
}

export default App;
